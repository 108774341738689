import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'slick-carousel/slick/slick-theme.css';
import 'luminous-lightbox/dist/luminous-basic.min.css';
import "./sass/style.scss";
import { Luminous, LuminousGallery } from 'luminous-lightbox';
import 'viewport-extra';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

AOS.init({
  offset: 50,
  duration: 1000,
  easing: 'linear',
  once: true,
});

new LuminousGallery(document.querySelectorAll(".gallery_list_item_link"));
if(document.querySelector('.calendar') ) {
  new Luminous(document.querySelector(".calendar"));
}

$(function(){

  $('.nav-toggle').on('click', function () {
    $('.gNav_toggle').toggleClass('active');
    $('.gNav_overlay').toggleClass('open');
    $('.siteHead').toggleClass('visible');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });

  if(document.querySelector('#tabs') ) {
    $('.about_tabs_item_tab').click(function(){
      $('#tabs .panel').hide();
      $('.about_tabs_item_tab').removeClass('is-current');
      $(this).toggleClass('is-current');
      $(this.hash).fadeIn();
      return false;
    });
    $('.about_tabs_item_tab:eq(0)').trigger('click');

    //URL取得とチェック
    var url = location.href;
    url = (url.match(/\?id=tab\d+$/) || [])[0];
    console.log(url);

    //取得したURLを「?」で分割
    var params = url.split("?");

    //params内のデータを「=」で分割
    var tab = params[1].split("=");

    //tab内のデータをtabnameに格納
    if($(tab).length){
      var tabname = tab[1];
      console.log(tabname);
    } else{
      var tabname = "tab1";
      console.log(tabname);
    }

    //コンテンツ非表示&amp;タブを非アクティブ
    $('#tabs .panel').hide();
    $('.about_tabs_item_tab').removeClass('is-current');

    //何番目のタブかを格納
    var tabno = $('.about_tabs_item#' + tabname).index();

    //コンテンツ表示
    $('#tabs .panel').eq(tabno).fadeIn();

    //タブのアクティブ化
    $('.about_tabs_item_tab').eq(tabno).addClass('is-current');

  };

  $('.smoothScroll').click(function(){
    // 移動先を50px上にずらす
    const adjust = 100;
    // スクロールの速度
    const speed = 600; // ミリ秒
    // アンカーの値取得
    const href= $(this).attr("href");
    // 移動先を取得
    const target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を調整
    const position = target.offset().top - adjust;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'linear');
    return false;
  });
});

if(document.querySelector('.scrollAnime') ) {

  /*  shipCurve */
  let shipStart = gsap.timeline();
  ScrollTrigger.create({
    trigger: ".siteBody",
    start: "1 top",
    toggleClass: { targets: ".scrollAnime", className: "is-scroll" },
    invalidateOnRefresh: true,
    onEnter:() => document.querySelector('.scrollAnime_ship_move').classList.remove('is-hidden'),
    onLeaveBack:() => document.querySelector('.scrollAnime_ship_move').classList.add('is-hidden'),
  });

  ScrollTrigger.matchMedia({

    // 960px以上
    "(min-width: 900px)": function() {
      /*  shipCurve */
      let shipCurve = gsap.timeline();
      ScrollTrigger.create({
        animation: shipCurve,
        trigger: ".scrollAnime_river",
        start: "10% 50%",
        end: "90% 50%",
        scrub: true,
        invalidateOnRefresh: true,
        // markers: true
      });
      shipCurve.to(".scrollAnime_ship_svg", { x: "0vw",rotation: -20, duration: 3.5, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_move", { opacity: 0, duration: 1.5, ease: "Power0.easeNone" }, "<")
      shipCurve.to(".scrollAnime_ship_svg", { x: "9vw", rotation: -10, duration: 6.7, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "9vw", rotation: 0, duration: 5.8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "0vw", rotation: 30, duration: 12, ease: "Power1.easeIn" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-12vw", rotation: 10, duration: 8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-12vw", rotation: 0, duration: 8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-8vw", rotation: -10, duration: 6.8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-3.6vw", rotation: 0, duration: 6, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { y: "10vh", opacity: 0, rotation: 0, duration: 10, ease: "Power0.easeNone" })
    },

    // 599px以下
    "(max-width: 899px)": function() {
      /*  shipCurve */
      let shipCurve = gsap.timeline();
      ScrollTrigger.create({
        animation: shipCurve,
        trigger: ".scrollAnime_river",
        start: "15% 50%",
        end: "95% 50%",
        scrub: true,
        invalidateOnRefresh: true,
        // markers: true
      });
      shipCurve.to(".scrollAnime_ship_svg", { x: "0vw",rotation: -20, duration: 3.5, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_move", { opacity: 0, duration: 1.5, ease: "Power0.easeNone" }, "<")
      shipCurve.to(".scrollAnime_ship_svg", { x: "9vw", rotation: -10, duration: 6.7, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "9vw", rotation: 0, duration: 3, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "0vw", rotation: 30, duration: 12, ease: "Power1.easeIn" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-12vw", rotation: 10, duration: 8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-12vw", rotation: 0, duration: 8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-8vw", rotation: -10, duration: 6.8, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { x: "-3.6vw", rotation: 0, duration: 6, ease: "Power0.easeNone" })
      shipCurve.to(".scrollAnime_ship_svg", { y: "2vh", opacity: 0, rotation: 0, duration: 3, ease: "Power0.easeNone" })
    },

  });



  gsap.set(".scrollAnime_story_bird-1",{
    xPercent: 200, //右100pxの位置に設定を変える
    y: "10vw",
  });
  gsap.set(".scrollAnime_story_bird-2",{
    xPercent: 100, //右100pxの位置に設定を変える
  });
  /*  forestPop */
  let forestPop = gsap.timeline();
  ScrollTrigger.create({
    animation: forestPop,
    trigger: ".scrollAnime_river",
    start: "20% 50%",
    end: "60% 50%",
    scrub: 2,
    invalidateOnRefresh: true,
    // markers: true
  });
  forestPop.to(".scrollAnime_river_forest_left", { xPercent: 100, duration: 1, ease: "Power0.easeNone" })
  forestPop.to(".scrollAnime_river_forest_right", { xPercent: -100, duration: 1, ease: "Power0.easeNone" }, "<")
  forestPop.to(".scrollAnime_river_forest_left-1", { xPercent: 100, duration: 1, ease: "Power0.easeNone" }, "<0.5")
  forestPop.to(".scrollAnime_river_forest_right-1", { xPercent: -100, duration: 1, ease: "Power0.easeNone" }, "<")
  forestPop.to(".scrollAnime_story_bird-1", { x: "-110vw", y: "-10vw", duration: 2, ease: "Power0.easeNone" }, "<")
  forestPop.to(".scrollAnime_story_bird-2", { x: "-110vw", y: "-10vw", duration: 2, ease: "Power0.easeNone" }, "<")
  forestPop.to(".scrollAnime_river_forest_left-2", { xPercent: 100, duration: 1, ease: "Power0.easeNone" })
  forestPop.to(".scrollAnime_river_forest_right-2", { xPercent: -100, duration: 1, ease: "Power0.easeNone" }, "<")
};

/* ----------------------------------
ローディング
---------------------------------- */

const loading = document.getElementById('loading');
/* 読み込み完了 */
window.addEventListener('load', stopload);

/* 6秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');
}


/* ----------------------------------
画面高さをCSS変数に代入（100vh用）
---------------------------------- */

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

// 初期化
setFillHeight();

/* ----------------------------------
ヘッダー動作
---------------------------------- */
const header = document.querySelector('.siteHead');
if(document.querySelector('.scrollAnime') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.scrollAnime'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
};

